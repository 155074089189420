import React from "react";
import {
  addUtmParams,
  urlBookADemo,
  urlCreateAccount,
} from "@userbrain/website-ui";

export function CompareLastParagraph() {
  const hrefBookADemo = addUtmParams(urlBookADemo, "website", "compare");
  const hrefCreateAccount = addUtmParams(
    urlCreateAccount,
    "website",
    "compare"
  );
  return (
    <p>
      <a href={hrefCreateAccount}>Start your free trial</a> with Userbrain and
      experience the difference for yourself. Still have some unanswered
      questions? <a href={hrefBookADemo}>Schedule a call</a> with the Userbrain
      team.
    </p>
  );
}
